import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Title = styled.h1`
  margin: 0 1rem;
`

const ImageStyle = css`
  width: 100%;

  @media screen and (min-width: 920px) {
    object-fit: scale-down;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.25);
    border: solid 1px rgba(0, 0, 0, 0.2);
    max-width: 40rem;
    max-height: 40rem;
  }
`

const MainImage = styled.img`
  ${ImageStyle};
`

const Post = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;

  a {
    color: #e8a2a9;
    text-decoration: underline;
  }

  ul {
    width: 90%;
    list-style-position: inside;
  }

  ul > li > ul {
    margin-left: 1.5rem;
  }

  li > p {
    display: inline;
  }

  img {
    ${ImageStyle};
  }

  @media screen and (min-width: 920px) {
    max-width: 920px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`

const BlogPost = ({ data }) => {
  const { blogPost } = data
  return (
    <Layout>
      <Seo
        title={`${blogPost.frontmatter.title} Sing Smart Studios | Townsville Singing School`}
        keywords={blogPost.excerpt.split(" ")}
      />
      <Container>
        <MainImage
          src={blogPost.frontmatter.thumbnail}
          alt={blogPost.frontmatter.title}
        />
        <Title>{blogPost.frontmatter.title}</Title>
        <Post dangerouslySetInnerHTML={{ __html: blogPost.html }} />
      </Container>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query ($slug: String!) {
    blogPost: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        thumbnail
        date(formatString: "D MMM YY")
      }
    }
  }
`
